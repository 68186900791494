/* eslint-disable no-var */

var Webflow = require('../BaseSiteModules/webflow-lib');
var siteModule = require('./modules/SplineSiteModule');

Webflow.define(
  'spline',
  (module.exports = function () {
    return {
      createInstance: siteModule.createInstance,
      destroyInstance: siteModule.destroyInstance,
      getInstance: siteModule.getInstance,
      setLoadHandler: siteModule.setLoadHandler,
      init: siteModule.init,
      destroy: siteModule.destroy,
      ready: siteModule.ready,
    };
  })
);
