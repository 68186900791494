/* eslint-env browser */

let SplineApp;

async function importSplineRuntime() {
  const {Application} = await import(
    // eslint-disable-next-line import/no-unresolved, import/extensions
    'https://unpkg.com/@splinetool/runtime/build/runtime.js'
  );
  SplineApp = Application;
}

const cache = new Map();
const callbacks = new Map();
const loadEvent = new Event('w-spline-load');

class SplineInstance {
  spline;
  container;

  destroy() {
    if (this.container) {
      cache.delete(this.container);
      callbacks.delete(this.container);
    }
    this.spline?.dispose?.();
  }

  async load(container, scene) {
    if (!SplineApp) {
      await importSplineRuntime();
    }
    const canvas = container.querySelector('canvas');
    const spline = new SplineApp(canvas);
    await spline.load(scene);
    cache.set(container, this);
    this.container = container;
    this.spline = spline;
    container.dispatchEvent(loadEvent);
    if (callbacks.has(container)) {
      callbacks.get(container)(spline);
      callbacks.delete(container);
    }
  }
}

const getSplineElements = () =>
  Array.from(document.querySelectorAll('[data-animation-type="spline"]'));

export const createInstance = async (container, scene) => {
  let splineInstance = cache.get(container);
  if (splineInstance == null) {
    splineInstance = new SplineInstance();
  }
  await splineInstance.load(container, scene);
  return splineInstance;
};

export const destroyInstance = (element) => {
  const splineInstance = cache.get(element);
  splineInstance?.destroy();
};

export const getInstance = (element) => {
  return cache.get(element);
};

export const setLoadHandler = (element, callback) => {
  callbacks.set(element, callback);
};

export const init = () => {
  getSplineElements().forEach((element) => {
    const scene = element.getAttribute('data-spline-url');
    if (scene) {
      createInstance(element, scene);
    }
  });
};

export const destroy = () => {
  getSplineElements().forEach(destroyInstance);
};

export const ready = init;
